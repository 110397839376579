import { Chip, ChipProps, Divider, Grid, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { RiskScore } from "@/gql/graphql";

export const RiskField: React.FC<{ name: React.ReactNode; value: RiskScore | null }> = ({ name, value }) => {
    return (
        <Stack>
            <Stack justifyContent="center" sx={{ height: "52px", width: "100%" }}>
                <Grid container alignItems="center" width="100%">
                    <Grid xs={4} alignContent="center" paddingLeft={3}>
                        <Typography variant="textSm">{name}</Typography>
                    </Grid>
                    <Grid xs={8} textAlign="end" alignItems="end" paddingRight={3}>
                        <RiskChip value={value} />
                    </Grid>
                </Grid>
            </Stack>
            <Divider />
        </Stack>
    );
};

const getRiskChipProps = (value: RiskScore): { color: ChipProps["color"]; label: React.ReactNode } => {
    switch (value) {
        case "LOW":
            return {
                color: "success",
                label: <FormattedMessage defaultMessage="Low" description="'Low' risk chip label" />,
            };
        case "VERY_LOW":
            return {
                color: "success",
                label: <FormattedMessage defaultMessage="Very Low" description="'Very Low' risk chip label" />,
            };
        case "MEDIUM":
            return {
                color: "warning",
                label: <FormattedMessage defaultMessage="Medium" description="'Medium' risk chip label" />,
            };
        case "HIGH":
            return {
                color: "error",
                label: <FormattedMessage defaultMessage="High" description="'High' risk chip label" />,
            };
        case "VERY_HIGH":
            return {
                color: "error",
                label: <FormattedMessage defaultMessage="Very High" description="'Very High' risk chip label" />,
            };
        default:
            return {
                color: "error",
                label: <FormattedMessage defaultMessage="Unknown" description="'Unknown' risk chip label" />,
            };
    }
};

export const RiskChip = ({ value }: { value?: RiskScore | null }) => {
    if (value === null || value === undefined) {
        return null;
    }
    const { color, label } = getRiskChipProps(value);
    console.log(color);
    return (
        <Stack direction="row" justifyContent="end" alignItems="center">
            <Chip color={color} label={label} />
        </Stack>
    );
};
