import { DocumentListFiles } from "@/components/OnboardingSupplier/OnboardingDocuments/DocumentList";

const EXTENSION_TO_CONTENT_TYPE_MAP: { [key: string]: string } = {
    ".pdf": "application/pdf",
    ".csv": "text/csv",
    ".txt": "text/plain",
    ".rtf": "application/rtf",
    ".xls": "application/vnd.ms-excel",
    ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".json": "application/json",
    ".ppt": "application/vnd.ms-powerpoint",
    ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ".doc": "application/msword",
    ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".xml": "application/xml",
    ".tif": "image/tiff",
    ".tiff": "image/tiff",
    ".jpeg": "image/jpeg",
    ".jpg": "image/jpeg",
    ".png": "image/png",
    ".xlsm": "application/vnd.ms-excel.sheet.macroEnabled.12",
};

export function getContentType(extension: string): string {
    return EXTENSION_TO_CONTENT_TYPE_MAP[extension.toLowerCase()];
}

export function getFileExtension(fileName: string): string | undefined {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex === -1 || lastDotIndex === fileName.length - 1) {
        // No dot found or dot is at the end with no extension
        return undefined;
    }
    return fileName.substring(lastDotIndex).toLowerCase();
}

/*  
    After generating random names for files stored in Cloud Storage we now save the name 
    as a metadata field and extract it again here. Falls back to the bucket filename
    if this filename field is not found. Mimetypes before this were hardcoded as 
    octet-stream hence the fallback to that, too. 
*/
export const getNameAndContentType = (file: DocumentListFiles): { originalFileName: string; contentType: string } => {
    if (!file.metaJson || JSON.parse(file.metaJson).originalfilename === undefined) {
        return { originalFileName: encodeURIComponent(file.name), contentType: "application/octet-stream" };
    }

    const { originalfilename } = JSON.parse(file.metaJson);
    const fileExt = getFileExtension(originalfilename) ?? "";

    return {
        originalFileName: originalfilename,
        contentType: getContentType(fileExt) || "application/octet-stream",
    };
};
