import { formatValue } from "@ignite-analytics/locale";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedDate } from "react-intl";

import { CustomFields_SupplierCustomFieldFragment, SupplierCustomFieldType } from "@/gql/graphql";

import { getLabel } from "./CustomFields/EditCustomField/NaceField/helpers";
import { NACEOptions } from "./CustomFields/EditCustomField/NaceField/NACEOptions";
import { Chip, classificationChipColors as ChipColors } from "./CustomFields/ReadCustomField";

import { ClassificationCustomField } from ".";
export const formatReadCustomField = (customField: CustomFields_SupplierCustomFieldFragment): JSX.Element => {
    const { dataJson, fieldType } = customField;

    if (dataJson == undefined) {
        return <React.Fragment />;
    }

    const value = JSON.parse(dataJson);

    if (value === "" || value == null) {
        return <React.Fragment />;
    }

    let childComponent = null;
    switch (fieldType) {
        case SupplierCustomFieldType.Date: {
            childComponent = <FormattedDate value={value} />;
            break;
        }
        case SupplierCustomFieldType.Number: {
            childComponent = formatValue(value, 0);
            break;
        }
        case SupplierCustomFieldType.Nace: {
            const option = NACEOptions.find((opt) => opt.nace === value?.slice(0, 5)) ?? null;
            childComponent = getLabel(option);
            break;
        }
        case SupplierCustomFieldType.MonetaryAmount: {
            childComponent = formatValue(value, 0);
            break;
        }
        case SupplierCustomFieldType.Aggregation:
        case SupplierCustomFieldType.Spend:
            childComponent = formatValue(value, 2);
            break;
        case SupplierCustomFieldType.Classification: {
            const valueById = (customField as ClassificationCustomField)?.valueById;
            childComponent = (
                <Stack direction="row" gap={1} justifyContent="end" alignItems="center" flexWrap="wrap">
                    {value?.map((id: string, i: number) => {
                        return (
                            <Chip color={ChipColors[i % value.length]} key={`${id}_${i}`}>
                                <Typography variant="textSm">{valueById?.get(id)}</Typography>
                            </Chip>
                        );
                    })}
                </Stack>
            );
            break;
        }
        default: {
            childComponent = value;
        }
    }
    return <Typography variant="textSm">{childComponent}</Typography>;
};

export const formatWriteCustomField = (jsonData: string, fieldType: SupplierCustomFieldType): string => {
    if (jsonData == undefined) {
        return "";
    }
    const value = JSON.parse(jsonData);

    if (value === "" || value == null) {
        return "";
    }
    switch (fieldType) {
        case SupplierCustomFieldType.Date: {
            return value.split("T")[0];
        }
        default: {
            return value;
        }
    }
};
