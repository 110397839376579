import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import React from "react";

import { useUser } from "./providers/UserContext";
import { AppRoutes } from "./routes";

const App: React.FC = () => {
    const user = useUser();

    return (
        <FeatureToggleContextProvider userEmail={user.email} tenant={user.tenant}>
            <AppRoutes />
        </FeatureToggleContextProvider>
    );
};
export default App;
