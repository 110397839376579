import { Button, TextField, Typography, Dialog, DialogContent, Input, Switch, Stack, Chip } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useAlert } from "@/providers";

import { normalizeDateString } from "./contractDate";

export type NewContract = {
    title: string;
    description: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    supplierId: string | undefined;
    isPrivate: boolean;
};

type ContractModalProps = {
    open: boolean;
    onClose: () => void;
    prefilledSupplier: { name: string; id: string };
    onContractAdded: () => void;
};

export const ContractModal = ({ open, onClose, prefilledSupplier, onContractAdded }: ContractModalProps) => {
    const [loading, setLoading] = useState(false);
    const locale = navigator.language;
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const [contract, setContract] = useState<NewContract>({
        title: "",
        description: undefined,
        startDate: undefined,
        endDate: undefined,
        supplierId: prefilledSupplier.id,
        isPrivate: false,
    });

    function reset() {
        setContract({
            title: "",
            description: undefined,
            startDate: undefined,
            endDate: undefined,
            supplierId: prefilledSupplier.id,
            isPrivate: false,
        });
    }

    const handleSave = async (contract: NewContract): Promise<void> => {
        try {
            setLoading(true);
            const res = await fetch(`${process.env.REACT_APP_CONTRACTS_API_URL ?? ""}/contracts`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(contract),
            });
            if (res.status === 201) {
                onContractAdded();
            }
        } catch (error) {
            alertUser({
                value: formatMessage({
                    defaultMessage: "An error occurred while submitting contract information",
                    description: "Alert message when creating a contract",
                }),
                title: formatMessage({
                    defaultMessage: "Error",
                    description: "Error",
                }),
                severity: "error",
            });
            Sentry.captureException(error, {
                tags: { app: "onboarding-app", message: "Failed to submit contract" },
            });
        } finally {
            setLoading(false);
        }
    };

    function handleClose() {
        reset();
        onClose();
    }

    return (
        <Dialog open={open} onClose={() => handleClose()}>
            <DialogContent>
                <Stack gap={3} width="485px">
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Add new contract" />
                    </Typography>
                    <TextField
                        label={formatMessage({ defaultMessage: "Contract title" })}
                        placeholder={formatMessage({ defaultMessage: "Contract title" })}
                        required
                        variant="outlined"
                        value={contract.title}
                        onChange={(e) => {
                            setContract({ ...contract, title: e.target.value });
                        }}
                    />

                    <Stack direction="row" gap={3} justifyContent="space-around">
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                            <DesktopDatePicker
                                timezone="UTC"
                                format="DD/MM/YYYY"
                                maxDate={normalizeDateString(contract.endDate)}
                                label={formatMessage({ defaultMessage: "Start date" })}
                                onChange={(value) => {
                                    setContract({ ...contract, startDate: value?.format() });
                                }}
                                slotProps={{
                                    textField: {
                                        required: true,
                                    },
                                }}
                            />

                            <DesktopDatePicker
                                timezone="UTC"
                                format="DD/MM/YYYY"
                                minDate={normalizeDateString(contract.startDate)}
                                label={formatMessage({ defaultMessage: "End date" })}
                                onChange={(value) => {
                                    setContract({ ...contract, endDate: value?.format() });
                                }}
                                slotProps={{
                                    textField: {
                                        required: true,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>

                    <Stack spacing={1} maxWidth="fit-content">
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Supplier" />
                        </Typography>
                        <Chip label={prefilledSupplier.name} color="neutral" />
                    </Stack>

                    <Stack gap={0.5}>
                        <Typography variant="textSm" fontWeight={500}>
                            <FormattedMessage defaultMessage="Description" />
                        </Typography>
                        <Input
                            disableUnderline
                            multiline
                            minRows={3}
                            placeholder={formatMessage({ defaultMessage: "Description of contract" })}
                            value={contract.description}
                            onChange={(e) => {
                                setContract({ ...contract, description: e.target.value });
                            }}
                        />
                    </Stack>

                    <Stack direction="row" gap={1.25}>
                        <Switch
                            checked={!contract.isPrivate}
                            onChange={() => {
                                setContract({ ...contract, isPrivate: !contract.isPrivate });
                            }}
                            color="primary"
                        />
                        <Stack>
                            <Typography fontWeight={500} variant="textSm">
                                <FormattedMessage defaultMessage="Public contract" />
                            </Typography>
                            <Typography variant="textSm" color="GrayText">
                                <FormattedMessage defaultMessage="Public contracts are visible to all users. If toggled off, they will be restricted to only administrators and contract responsibles of the contract." />
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack gap={1.5} direction="row" justifyContent="flex-end">
                        <Button onClick={() => handleClose()} color="secondary" size="small">
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <Button
                            disabled={loading || contract.title.length === 0}
                            size="small"
                            onClick={() => {
                                handleSave(contract);
                            }}
                        >
                            <FormattedMessage defaultMessage="Save" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
