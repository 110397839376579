import { useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";
import { useAlert } from "@/providers/Alerts";
import { useUser } from "@/providers/UserContext";

interface ApproveModalProps {
    open: boolean;
    onClose: () => void;
    supplierName: string;
    supplierId: string;
    supplierCountry?: string | null;
    supplierOrgNumber?: string | null;
    onClickAddContract: () => void;
    hasContract: boolean;
    loading: boolean;
    refetch: () => void;
}

const ApproveSupplier_OnboardingMutation = graphql(`
    mutation Onboarding_ApproveSupplier($input: ApproveSupplierInput!) {
        approveSupplier(input: $input) {
            ok
        }
    }
`);

export const ApproveModal: React.FC<ApproveModalProps> = ({
    open,
    onClose,
    supplierName,
    supplierId,
    supplierCountry,
    supplierOrgNumber,
    onClickAddContract,
    hasContract,
    loading,
    refetch,
}) => {
    const [approveSupplier] = useMutation(ApproveSupplier_OnboardingMutation);
    const [confirmed, setConfirmed] = useState(false);
    const navigate = useNavigate();
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const path = location.pathname.split(/onboarding/);
    const basePath = path[0];

    const { firstName, lastName } = useUser();
    const [approved, setApproved] = useState<boolean>(true);
    const [note, setNote] = useState("");

    const finalizeOnboarding = () => {
        approveSupplier({
            variables: {
                input: {
                    supplierID: supplierId,
                    approved: approved,
                    note: note,
                },
            },
            onCompleted: () => {
                navigate(`${basePath}supplier-page/${supplierId}/overview`); // until we navigate to the approve dialog
            },
            onError: () => {
                alertUser({
                    title: formatMessage({
                        defaultMessage: "Failed to finalize onboarding",
                        description: "Failed to approve supplier alert message",
                    }),
                    value: formatMessage({
                        defaultMessage: "Please try again later",
                        description: "Please try again later alert message",
                    }),
                    severity: "error",
                });
            },
        });
        if (approved)
            track("Onboarding Page: Approved supplier", {
                supplierId: supplierId,
                note: note,
                gaveReason: !!note,
            });
        else
            track("Onboarding Page: Rejected supplier", {
                supplierId: supplierId,
                note: note,
                gaveReason: !!note,
            });
    };

    useEffect(() => {
        refetch();
    }, [open, refetch]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <IconButton onClick={onClose} sx={{ position: "absolute", top: 8, right: 8 }}>
                    <X />
                </IconButton>
                <Stack spacing={1}>
                    <Typography variant="textLg">
                        <FormattedMessage
                            defaultMessage="Finalize onboarding for {supplierName}"
                            values={{ supplierName }}
                            description="Finalize onboarding header"
                        />
                    </Typography>
                    <Typography variant="textSm" color="text.text-helper">
                        <FormattedMessage
                            defaultMessage="Review and confirm the following details to continue."
                            description="Finalize onboarding header"
                        />
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 4 }}>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        <Typography variant="textMd" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Details"
                                description="Details section header, onboarding approval dialog"
                            />
                        </Typography>
                        <Paper elevation={2}>
                            <Grid container width="100%" padding={3}>
                                <Grid xs={4} pb={1}>
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage defaultMessage="Supplier name" description="Supplier name" />
                                    </Typography>
                                </Grid>
                                <Grid xs={8} textAlign="end">
                                    <Typography variant="textSm" color="text.text-helper">
                                        {supplierName}
                                    </Typography>
                                </Grid>
                                <Grid xs={4} pb={1}>
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage defaultMessage="Country" description="Supplier country" />
                                    </Typography>
                                </Grid>
                                <Grid xs={8} textAlign="end">
                                    <Typography variant="textSm" color="text.text-helper">
                                        {supplierCountry}
                                    </Typography>
                                </Grid>
                                <Grid xs={4} pb={1}>
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage
                                            defaultMessage="Org number"
                                            description="Supplier org number"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid xs={8} textAlign="end">
                                    <Typography variant="textSm" color="text.text-helper">
                                        {supplierOrgNumber}
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage
                                            defaultMessage="Contract"
                                            description="Contract added field"
                                        />
                                    </Typography>
                                </Grid>
                                {loading && (
                                    <Grid xs={8} textAlign="end" display="flex" justifyContent="flex-end">
                                        <Skeleton width={100} height={40} />
                                    </Grid>
                                )}
                                {!loading && (
                                    <Grid xs={8} textAlign="end">
                                        {hasContract ? (
                                            <Chip
                                                variant="status"
                                                label={formatMessage({ defaultMessage: "Added" })}
                                                color="success"
                                                size="small"
                                            />
                                        ) : (
                                            <Stack
                                                spacing={1}
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="flex-end"
                                            >
                                                <Chip
                                                    variant="status"
                                                    label={formatMessage({ defaultMessage: "Not added" })}
                                                    color="warning"
                                                    size="small"
                                                />
                                                <Button
                                                    size="2xsmall"
                                                    color="secondary"
                                                    onClick={() => onClickAddContract()}
                                                >
                                                    <FormattedMessage defaultMessage="Add contract" />
                                                </Button>
                                            </Stack>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Approval process"
                                description="Final confirmation in onboarding approval dialog"
                            />
                        </Typography>
                        <RadioGroup
                            aria-label="approval"
                            name="approval"
                            value={approved}
                            onChange={() => setApproved(!approved)}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio sx={{ padding: "4px" }} />}
                                label={
                                    <Stack alignItems="flex-start">
                                        <Typography variant="textSm">
                                            <FormattedMessage defaultMessage="Approve" />
                                        </Typography>
                                        <Typography variant="textSm" color={(theme) => theme.palette.text.textHelper}>
                                            <FormattedMessage defaultMessage="Choose this option if you are satisfied with the onboarding details and wish to proceed with approving the supplier." />
                                        </Typography>
                                    </Stack>
                                }
                                sx={{ alignItems: "flex-start", marginY: 1 }}
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio sx={{ padding: "4px" }} />}
                                label={
                                    <Stack alignItems="flex-start">
                                        <Typography variant="textSm">
                                            <FormattedMessage defaultMessage="Reject" />
                                        </Typography>
                                        <Typography variant="textSm" color={(theme) => theme.palette.text.textHelper}>
                                            <FormattedMessage defaultMessage="Choose this option if the supplier does not meet the necessary criteria, and you need to halt the onboarding process." />
                                        </Typography>
                                    </Stack>
                                }
                                sx={{ alignItems: "flex-start", marginY: 1 }}
                            />
                        </RadioGroup>
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Reason for decision" description="Reason for decision" />
                        </Typography>
                        <TextField
                            multiline
                            rows={4}
                            variant="outlined"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            fullWidth
                            placeholder={formatMessage({
                                defaultMessage: "Provide a reason for your decision",
                                description: "Reason for decision helper text",
                            })}
                            InputProps={{
                                style: {
                                    padding: 0,
                                },
                            }}
                        />
                        <FormControlLabel
                            label={
                                <Stack>
                                    <Typography variant="textSm" color="text.textSecondary" fontWeight={500}>
                                        <FormattedMessage
                                            defaultMessage="As {firstName} {lastName}, I confirm this action."
                                            values={{ firstName, lastName }}
                                            description="Confirmation checkbox"
                                        />
                                    </Typography>
                                    <Typography variant="textSm" color="text.text-helper">
                                        <FormattedMessage
                                            defaultMessage="Your digital signature will be attached to this approval decision."
                                            description="Digital signature disclaimer"
                                        />
                                    </Typography>
                                </Stack>
                            }
                            control={<Checkbox onChange={(e) => setConfirmed(e.target.checked)} />}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack justifyContent="space-between" alignItems="center" direction="row" width="100%">
                    <Button color="secondary" onClick={onClose}>
                        <FormattedMessage defaultMessage="Go back" description="Go back button text" />
                    </Button>

                    <Stack direction="row" spacing={2}>
                        <Button onClick={() => finalizeOnboarding()} disabled={!confirmed}>
                            <FormattedMessage defaultMessage="Confirm" description="Confirm button text" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
