import { useMutation, useQuery } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Accordion, AccordionDetails, alpha, Button, Stack, Typography } from "@mui/material";
import debounce from "lodash/debounce";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import {
    ClassificationOptions,
    CustomFields_SupplierCustomFieldFragment,
    SupplierCustomFieldType,
    SupplierInformation_ColumnsFragment,
    SupplierInformation_SupplierFragment,
    SupplierInformation_SupplierFragmentDoc,
} from "@/gql/graphql";
import { useAlert } from "@/providers";

import { AccordionSummary } from "..";

import { CustomFields } from "./CustomFields/CustomFields";
import { KnownFields } from "./KnownFields";
import { OnboardingFields } from "./OnboardingFields";

export interface ClassificationCustomField extends CustomFields_SupplierCustomFieldFragment {
    valueById: Map<string, string>;
}

const SupplierInformation_GetSupplierFieldsQuery = graphql(`
    query SupplierInfoCard_GetSupplierMeta {
        getSupplierTableMeta {
            columns {
                ...SupplierInformation_Columns
            }
        }
    }
`);

const SupplierInformation_UpdateSupplierFieldMutation = graphql(`
    mutation SupplierInformation_UpdateSupplierField($input: UpdateSupplierFieldInput!) {
        updateSupplierField(input: $input) {
            supplier {
                ...SupplierInformation_Supplier
            }
        }
    }
`);

graphql(`
    fragment SupplierInformation_Supplier on Supplier {
        id
        ...KnownFields_Supplier
        customFields {
            ...CustomFields_SupplierCustomField
        }
        ...OnboardingFields_Supplier
    }

    fragment SupplierInformation_Columns on SupplierTableColumn {
        id
        name
        type
        typeOptions {
            ... on SelectOptions {
                choices
            }
            ... on ClassificationOptions {
                groups {
                    id
                    value
                    level
                }
            }
        }
    }
`);

interface SupplierInformationProps {
    open: boolean;
    onChange: (_: React.SyntheticEvent, newExpanded: boolean) => void;
    supplier: SupplierInformation_SupplierFragment;
    isEditor: boolean;
}

export const SupplierInformation: React.FC<SupplierInformationProps> = ({ open, onChange, supplier, isEditor }) => {
    const [edit, setEdit] = useState(false);
    const [showMore, setShowMore] = useState(true);
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const [updateSupplierField] = useMutation(SupplierInformation_UpdateSupplierFieldMutation, {
        update: (cache, { data }) => {
            const updatedSupplier = data?.updateSupplierField?.supplier;
            cache.writeFragment({
                id: `Supplier:${supplier.id}`,
                fragment: SupplierInformation_SupplierFragmentDoc,
                fragmentName: "SupplierInformation_Supplier",
                data: {
                    ...updatedSupplier,
                },
            });
        },
    });

    const { data } = useQuery(SupplierInformation_GetSupplierFieldsQuery, {
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to fetch supplier information",
                    description: "Failed to fetch supplier information alert message",
                }),
                severity: "error",
            });
        },
    });

    const tableColumns = data?.getSupplierTableMeta?.columns ?? [];
    const selectOptions = getColumnSelectOptions(tableColumns);

    const knownFieldNames = tableColumns.reduce(
        (acc: Record<string, string>, curr: SupplierInformation_ColumnsFragment) => {
            if (curr.id === "name" || curr.id === "reg_nr" || curr.id === "country") {
                acc[curr.id] = curr.name;
            }
            return acc;
        },
        {}
    );

    const handleSave = (e: React.FormEvent) => {
        e.stopPropagation();
        setEdit(false);
    };

    const updateHandler = debounce(function (fieldId: string, fieldData: string) {
        let inputData: string | null = fieldData;
        if (fieldData === "") {
            inputData = null;
        }
        track("Onboarding: Updated supplier field", {
            source: "Onboarding modal",
            field: supplier.customFields.find((cf) => cf.fieldId === fieldId)?.name,
            value: fieldData,
        });
        updateSupplierField({
            variables: {
                input: {
                    fieldId,
                    fieldData: JSON.stringify(inputData),
                    id: supplier.id,
                },
            },
            onError: () => {
                alertUser({
                    value: formatMessage(
                        {
                            defaultMessage: "Failed to update supplier field with value: {value}",
                            description: "Failed to update supplier field alert message",
                        },
                        { value: fieldData }
                    ),
                    severity: "error",
                });
            },
        });
    }, 500);

    return (
        <Accordion
            sx={{
                top: 0,
                borderRadius: 1,
                zIndex: 0,
                overflowY: "auto",
                maxHeight: "45vh",
                borderColor: (theme) => (edit ? theme.palette.secondary.main : "none"),
                boxShadow: (theme) => (edit ? `0px 0px 0px 4px ${alpha(theme.palette.secondary.main, 0.2)}` : null),
                "&:before": {
                    display: "none",
                },
            }}
            expanded={open}
            onChange={(e) => {
                setEdit(false);
                onChange(e, !open);
            }}
        >
            <AccordionSummary id="panle-supplier-information" ariaControls="panle-supplier-information">
                <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage
                            defaultMessage="Supplier information"
                            description="Supplier information accordion title"
                        />
                    </Typography>
                    {open &&
                        (edit ? (
                            <Button id="save-button" size="2xsmall" color="primary" onClick={handleSave}>
                                <FormattedMessage defaultMessage="Save" description="Save button" />
                            </Button>
                        ) : (
                            <NoPermissionTooltip hasPermission={isEditor}>
                                <Button
                                    id="edit-button"
                                    size="2xsmall"
                                    color="secondary"
                                    disabled={!isEditor}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setEdit(true);
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Edit" description="Inline assessment button" />
                                </Button>
                            </NoPermissionTooltip>
                        ))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingBottom: 0 }}>
                <Stack>
                    <KnownFields
                        onUpdate={updateHandler}
                        edit={edit}
                        supplier={supplier}
                        knownFieldNames={knownFieldNames}
                    />
                    {!showMore && (
                        <>
                            {supplier?.customFields
                                .filter(
                                    // We dont want to show the assessment fields on the supplier page
                                    // so we filter them out here
                                    (cu: CustomFields_SupplierCustomFieldFragment) =>
                                        cu.fieldType !== SupplierCustomFieldType.Assessment
                                )
                                .map((c: CustomFields_SupplierCustomFieldFragment) => {
                                    if (c.fieldType === SupplierCustomFieldType.Classification) {
                                        const column = tableColumns.find(
                                            (tc: SupplierInformation_ColumnsFragment) => tc.id === c.fieldId
                                        );
                                        if (!column) {
                                            return c;
                                        }
                                        return {
                                            ...(c as ClassificationCustomField),
                                            valueById: new Map(
                                                (column.typeOptions as ClassificationOptions)?.groups?.map((g) => [
                                                    g.id,
                                                    g.value,
                                                ])
                                            ),
                                        };
                                    }
                                    return c;
                                })
                                .map((cf: CustomFields_SupplierCustomFieldFragment | ClassificationCustomField) => {
                                    return (
                                        <CustomFields
                                            onChange={updateHandler}
                                            edit={edit}
                                            customField={cf}
                                            selectOptions={selectOptions}
                                            key={cf?.fieldId}
                                        />
                                    );
                                })}
                            {supplier.onboarding && <OnboardingFields onboardingFields={supplier} />}
                        </>
                    )}
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        paddingBottom={2}
                        paddingTop={2}
                        sx={{
                            position: "sticky",
                            bottom: 0,
                            top: "auto",
                            backgroundColor: "white",
                        }}
                    >
                        <Button color="secondary" fullWidth onClick={() => setShowMore((prev) => !prev)}>
                            {showMore ? (
                                <FormattedMessage defaultMessage="Show more" description="Show more button" />
                            ) : (
                                <FormattedMessage defaultMessage="Show less" description="Show less button" />
                            )}
                        </Button>
                    </Stack>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};

function getColumnSelectOptions(columns: SupplierInformation_ColumnsFragment[]) {
    if (columns === undefined) {
        return;
    }
    const selectMap = new Map<string, string[]>();
    columns
        .filter((c) => c.type == "SELECT")
        .forEach((c) => {
            if (c.typeOptions?.__typename === "SelectOptions") {
                selectMap.set(c.id, c.typeOptions?.choices);
            }
        });
    return selectMap;
}
